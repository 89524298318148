import React from 'react';
import CONFIG from "../config/appConfig";
import Carousel, { autoplayPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

const Section = (props) => {
  let opacityClass = 'opacity-cover opacity-cover--' + props.opacityColor
  return(
    <div class="landing-section-content">
      <h1 class="font-color--black">
        {props.headline}
      </h1>
        {/* <img class="company-logo" src={CONFIG.COMPANY_LOGO}/> */}
      <h3 class="font-color--black">
        {props.subheading}
      </h3>
      <br />
      {props.storeButton? (
        <span>
          <a href={props.storeLink} class="btn" target="__blank">
            {props.storeText}
          </a>
          &nbsp;&nbsp;
        </span>
      ) : null}

      {props.videoButton ? (
        <span>
          <a href={props.videoLink} class="btn" target="_blank">
            <i class="fa fa-play"></i>
            {props.videoText}
          </a>
          &nbsp;&nbsp;
        </span>
      ) : null}

      {props.downloadButton ? (
        <span>
          <a href={props.downloadLink} class="btn" target="__blank">
            {props.downloadText}
          </a>
          &nbsp;&nbsp;
        </span>
      ) : null}
    </div>
  )
}

const CarouselPage = (props) => {
  let containerClass = 'container';
  let opacityClass = 'opacity-cover opacity-cover--' + props.opacityColor;
  let imageOpacityClass = 'opacity-cover opacity-cover--' + props.imageOpacityColor;

  if(props.splitScreen) containerClass += "-split";
  return (
    <div class="landing-header">
      <div className={containerClass}>
        {props.splitScreen ? 
        (<div class="landing-header--split-screen-container">
          {props.contentLeftSide ? (
            <div class="landing-header-pane section-pane">
              {props.contentLeftSide ? <Section {...props}/> : null}
              <div className={opacityClass}/> 
            </div>
          ) : null}
          {props.contentLeftSide ? (
            <div class="landing-header-pane section-pane" style={{backgroundImage: `url(${props.backgroundImage})`, backgroundSize: `100% 100%`, backgroundRepeat: `no-repeat`}}>
              {props.contentRightSide ? <Section {...props} /> : null}
              <div className={imageOpacityClass} />
            </div>
          ) : null}
          {props.contentRightSide ? (
            <div class="landing-header-pane section-pane">
              {props.contentLeftSide ? <Section {...props}/> : null}
              <div className={imageOpacityClass}/> 
            </div>
          ) : null}
          {props.contentRightSide ? (
            <div class="landing-header-pane section-pane" style={{backgroundImage: `url(${props.backgroundImage})`, backgroundSize: `100% 100%`, backgroundRepeat: `no-repeat`}}>
              {props.contentLeftSide ? <Section {...props} /> : null}
              <div className={opacityClass} />
            </div>
          ) : null}
          

        </div>)
        : (
          <div class="landing-header-full-screen">
            <h1 class="title-uppercase" >
              {props.headline}
            </h1>
            <h3>
              {props.subheading}
            </h3>
            <br />
            {props.videoButton ? (
              <a href={props.videoLink} class="btn" target="_blank">
                <i class="fa fa-play"></i>
                {props.videoText}
              </a>
            ) : null}
            &nbsp; &nbsp;
            {props.downloadButton ? (
              <a class="btn">
                {props.downloadText}
              </a>
            ) : null}
          </div>
        )
        }
      </div>    
    </div>
  )
}

export default CarouselPage;