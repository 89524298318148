import React from "react";
import CONFIG from "../config/appConfig";

const Footer = () => {
  return (
    <div class="footer" style={{backgroundColor: `${CONFIG.THEME.PRIMARY}`}}>
      &copy; {new Date().getFullYear()} Materni.
    </div>
  )
}

export default Footer;