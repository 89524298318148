import React, { Component } from 'react';

import ToolBar from "../components/toolBar";
import DiscountBanner from "../components/discountBanner";
import CarouselLandingSection from "../components/CarouselLandingSection";
import LandingSection from "../components/landingSection";
import { MailChimpForm } from '../components/mailChimpForm';
import SplitSection from "../components/splitSection";
import HowItWorksSection from "../components/howItWorksSection";
import FeatureSection from "../components/featureSection";
import ProductSection from "../components/productSection";
import VisionSection from "../components/visionSection";
import CompanyProfile from "../components/companyProfile";
import ContactSection from "../components/contactSection";
import Footer from "../components/footer";

import motherProducts from "../assets/img/mother-products.jpeg";
import biodegradable from "../assets/img/wellness-products.jpg";
import recycledNatural from "../assets/img/recycled-natural.jpeg";

import CONFIG from "../config/appConfig";

class PageContainer extends Component {
  render() {
    return (
      <div>
        {CONFIG.DISCOUNT_SETTINGS.IS_DISCOUNT ? <DiscountBanner /> : null}
        <ToolBar />
				<LandingSection />
        {/* {CONFIG.SITE_SECTIONS.LANDING_SECTION ? <CarouselLandingSection /> : null} */}
        <MailChimpForm />
				<HowItWorksSection />
				<FeatureSection />
        {/* <SplitSection 
          contentOnRight={true}
          imageFullCover={false}
          image={motherProducts}
          content={
            {
              headline: "Maternity Care",
              subheading: "We Drive For Better Products",
              description: "Life can become very difficult - especially in certain phases of our lives. Every product we make is intended to alleviate those moments of struggles and we hope that by introducing these products into your maternal routine, life as a mother becomes easier.",
              sectionColor: CONFIG.THEME.TERTIARY,
              fontColor: CONFIG.THEME.FONT_PRIMARY,
            }
          }
        />
        <SplitSection 
          contentOnRight={false}
          imageFullCover={false}
          image={motherProducts}
          content={
            {
              headline: "Biodegradable Products",
              subheading: "Conserving Nature",
              description: "By prioritizing Biodegradable Products, we believe that we can help shift the market and enhance consumer awareness regarding the impact of their purchasing decisions. Put your money towards a solution for environmental issues.",
              sectionColor: CONFIG.THEME.TERTIARY,
              fontColor: CONFIG.THEME.FONT_PRIMARY,
            }
          }
        />
        <SplitSection 
          contentOnRight={true}
          imageFullCover={false}
          image={recycledNatural}
          content={
            {
              headline: "Recycled Materials",
              subheading: "100% Natural and Renewable",
              description: "Materni is committed to selling high quality, natural products which are kind to the environment and minimize the negative impact to the planet. Recycled Materials is a key factor in our sustainable solution.",
              sectionColor: CONFIG.THEME.TERTIARY,
              fontColor: CONFIG.THEME.FONT_PRIMARY,
            }
          }
        /> */}
        {CONFIG.SITE_SECTIONS.COMPANY_PROFILE_SECTION ? <CompanyProfile /> : null}
        {CONFIG.SITE_SECTIONS.CONTACT_SECTION ? <ContactSection /> : null}
        <Footer />
      </div>
    )
  }
}

export default PageContainer;