import React from "react";
import CONFIG from "../config/appConfig";

const DiscountBanner = () => {

  let opacityClass = 'discount-banner font-color--black discount-cover opacity-cover--' + CONFIG.DISCOUNT_SETTINGS.DISCOUNT_BACKGROUND_COLOR;
  let fontClass = 'container text-center font-color--' + CONFIG.DISCOUNT_SETTINGS.DISCOUNT_FONT_COLOR
  return (
    <div>
      <div class={opacityClass}>
        <div class={fontClass}>
          {CONFIG.DISCOUNT_SETTINGS.DISCOUNT_TEXT ? (
            <h5>
              {CONFIG.DISCOUNT_SETTINGS.DISCOUNT_TEXT}
            </h5>
          ) : (
            <h5>
             Get a {CONFIG.DISCOUNT_SETTINGS.DISCOUNT}% discount on selected products until the {CONFIG.DISCOUNT_SETTINGS.DISCOUNT_EXP_DATE}
            </h5>
          )
        }
        </div>
      </div>
    </div>
  )
}

export default DiscountBanner;