import React from "react";
import CONFIG from "../config/appConfig";
import ProductTile from "./productTile";
import SplitSection from "./splitSection";
import bottleMeasurementSide from "../assets/img/product/materni-bottle-back.jpg";
import retractableNozzle from "../assets/img/product/materni-retractable-nozzle.jpg";
import explodedView from "../assets/img/product/materni-exploded-view.jpg";
import measurementMarkings from "../assets/img/product/materni-measurement-markings.jpg";


const HowItWorksSection = () => {
  return (
    <div id="instructions">
      <div class="how-it-works-section">
        <div class="container">
			<div class="landing-header">
				<div class="container-split">
					<div class="landing-header--split-screen-container">
						<div class="landing-header-pane how-it-works-section-pane how-it-works-picture-pane">
							<div class="how-it-works-section-image-cover">
								<img className="how-it-works-image" src={explodedView} />
							</div>
						</div>
						<div class="landing-header-pane how-it-works-section-pane how-it-works-content-pane">
						<div class="section-content">
							<h3 className="how-it-works-heading">
								How It Works
							</h3>
							&nbsp;
							<p class="body-text">
								Soft and made with recyclable EVA, this semi-transparent bottle is designed with an angled retractable nozzle to direct soothing relief to the right area with a gentle squeeze.
							</p>
							<br/>
							<span>
								<a href={CONFIG.HOW_IT_WORKS_SECTION.DOWNLOAD_LINK} class="btn" target="__blank">
									Download Instructions
								</a>
								&nbsp;&nbsp;
							</span>
						</div>
						</div>
					</div>
				</div>    
			</div>
		</div>
      </div>
    </div>
  )
}

export default HowItWorksSection;