import React, { Component } from 'react';
import CONFIG from "../config/appConfig";

const ToolBar = () => {
  return (
    <div className="header">
      <div class="company-logo-container">
        <img class="company-logo" src={CONFIG.COMPANY_LOGO}/>
        <a class="company-logo-font" href={CONFIG.COMPANY_URL}>
            {CONFIG.COMPANY_NAME}
        </a>
      </div>
      {CONFIG.NAVIGATION.map(item => (
        <div>
          <a href={item.LINK} className={"navbar-item"}>{item.NAME}</a>
        </div>
      )
      )}
      {CONFIG.TWITTER_URL !== "" ? (
        <div>
            <a href="#" target="_blank" class="btn btn-simple"><i class="fa fa-twitter"></i></a>
        </div>
      ) : null}
      {CONFIG.FACEBOOK_URL !== "" ? (
        <div>
          <a href="#" target="_blank" class="btn btn-simple"><i class="fa fa-facebook"></i></a>
        </div>
      ) : null}
    </div>
    // <nav class="navbar" style={{backgroundColor: `${CONFIG.THEME.WHITE}`}} role="navigation-demo" id="demo-navbar">
    //   <div class="navbar-container">
    //     <div class="navbar-header">
    //       <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navigation-example-2">
    //         <span class="sr-only">Toggle navigation</span>
    //         <span class="icon-bar"></span>
    //         <span class="icon-bar"></span>
    //         <span class="icon-bar"></span>
    //       </button>
    //       <div class="company-logo-container">
    //         <img class="company-logo" src={CONFIG.COMPANY_LOGO}/>
    //         <a class="company-logo-font" href={CONFIG.COMPANY_URL}>
    //             {CONFIG.COMPANY_NAME}
    //         </a>
    //       </div>
    //     </div>
    
    //     <div class="collapse navbar-collapse" id="navigation-example-2">
    //       <ul class="nav navbar-nav navbar-right">
    //         {CONFIG.NAVIGATION.map(item => (
    //           <li>
    //             <a href={item.LINK} className={"navbar-item"}>{item.NAME}</a>
    //           </li>
    //         )
    //         )}
    //         {CONFIG.TWITTER_URL !== "" ? (
    //           <li>
    //               <a href="#" target="_blank" class="btn btn-simple"><i class="fa fa-twitter"></i></a>
    //           </li>
    //         ) : null}
    //         {CONFIG.FACEBOOK_URL !== "" ? (
    //           <li>
    //             <a href="#" target="_blank" class="btn btn-simple"><i class="fa fa-facebook"></i></a>
    //           </li>
    //         ) : null}
    //        </ul>
    //     </div>
    //   </div>
    // </nav>  
  )
}

export default ToolBar;