import React from 'react';
import CONFIG from "../config/appConfig";
import { MailChimpForm } from './mailChimpForm';
import Carousel, { autoplayPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import materniBottleImage from "../assets/img/product/materni_spray.jpg";

let containerClass = 'container';
if(CONFIG.LANDING_SECTION.SPLIT_SCREEN) containerClass += "-split";

const Section = () => {
  return(
    // <div class="landing-section-content" style={{color: `${CONFIG.THEME.FONT_PRIMARY}`}}>   
		<div class="landing-section-content" style={{color: `black`}}>   
      <h1>
        {CONFIG.LANDING_SECTION.HEADLINE}
      </h1>
        {/* <img class="company-logo" src={CONFIG.COMPANY_LOGO}/> */}
      <h3>
        {CONFIG.LANDING_SECTION.SUB_HEAD}
      </h3>
      <br />
      {CONFIG.LANDING_SECTION.STORE_BTN ? (
        <span>
          <a href={CONFIG.LANDING_SECTION.STORE_LINK} class="btn" target="__blank">
            {CONFIG.LANDING_SECTION.STORE_TEXT}
          </a>
          &nbsp;&nbsp;
        </span>
      ) : null}

      {CONFIG.LANDING_SECTION.VIDEO_BTN ? (
        <span>
          <a href={CONFIG.LANDING_SECTION.VIDEO_LINK} class="btn" target="_blank">
            <i class="fa fa-play"></i>
            {CONFIG.LANDING_SECTION.VIDEO_TEXT}
          </a>
          &nbsp;&nbsp;
        </span>
      ) : null}

      {CONFIG.LANDING_SECTION.DOWNLOAD_BTN ? (
        <span>
          <a href={CONFIG.LANDING_SECTION.DOWNLOAD_LINK} class="btn" target="__blank">
            {CONFIG.LANDING_SECTION.DOWNLOAD_TEXT}
          </a>
          &nbsp;&nbsp;
        </span>
      ) : null}
    </div>
  )
}

const LandingSection = () => {
  return (
    <div class="landing-section-container">
			<div class="landing-header">
				<img class="landing-image" src={materniBottleImage} /> 
				<div class="blur-top"></div>
				<div class="blur-right"></div>
        <div class="landing-header-full-screen">
						<h1>
							{CONFIG.LANDING_SECTION.HEADLINE}
						</h1>
						<h3>
							{CONFIG.LANDING_SECTION.SUB_HEAD}
						</h3>
						<br />
						{CONFIG.LANDING_SECTION.STORE_BTN ? (
							<span>
								<a href={CONFIG.LANDING_SECTION.STORE_LINK} class="btn landing-section-btn" target="__blank">
									{CONFIG.LANDING_SECTION.STORE_TEXT}
								</a>
								&nbsp;&nbsp;
							</span>
						) : null}
						{CONFIG.LANDING_SECTION.VIDEO_BTN ? (
							<a href={CONFIG.LANDING_SECTION.VIDEO_LINK} class="btn" target="_blank">
								<i class="fa fa-play"></i>
								{CONFIG.LANDING_SECTION.VIDEO_TEXT}
							</a>
						) : null}
						&nbsp; &nbsp;
						{CONFIG.LANDING_SECTION.DOWNLOAD_BTN ? (
							<a class="btn">
								{CONFIG.LANDING_SECTION.DOWNLOAD_TEXT}
							</a>
						) : null}
					</div> 
					
			</div>
		</div>
		
  )
}

export default LandingSection;