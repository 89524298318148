import React from "react";
import CONFIG from "../config/appConfig";

const CompanyProfile = () => {
  return (
    <div class="section section-dark text-center landing-section">
      <div class="container">
        <h2>Let's talk about us</h2>
        <div class="col-md-4">
        <div class="team-player">
          <img src="../assets/paper_img/chet_faker_2.jpg" alt="Thumbnail Image" class="img-circle img-responsive"/>
          <h5>Chet Faker <br /><small class="text-muted">Music</small></h5>
          <p>You can write here details about one of your team members. You can give more details about what they do. Feel free to add some <a href="#">links</a> for people to be able to follow them outside the site.</p>
        </div>
        </div>
        <div class="col-md-4">
        <div class="team-player">
          <img src="../assets/paper_img/flume.jpg" alt="Thumbnail Image" class="img-circle img-responsive"/>
          <h5>Flume <br /><small class="text-muted">Production</small></h5>
          <p>You can write here details about one of your team members. You can give more details about what they do. Feel free to add some <a href="#">links</a> for people to be able to follow them outside the site.</p>
        </div>
        </div>
        <div class="col-md-4">
        <div class="team-player">
          <img src="../assets/paper_img/banks.jpg" alt="Thumbnail Image" class="img-circle img-circle img-responsive"/>
          <h5>Banks <br /><small class="text-muted">Music</small></h5>
          <p>You can write here details about one of your team members. You can give more details about what they do. Feel free to add some <a href="#">links</a> for people to be able to follow them outside the site.</p>
        </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyProfile;