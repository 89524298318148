import React, { Component } from 'react';
import Carousel, { autoplayPlugin } from '@brainhubeu/react-carousel';
import LandingSection from "./landingSection";
import CarouselPage from "./CarouselPage";
import newProductsBackground from "../assets/img/carousel-new-products.jpeg";

class CarouselLandingSection extends Component {
  constructor() {
    super()
    this.state = { 
      value: 0,
      timeOut: 5000,
      numSlides: 2, 
    };
    this.onChange = this.onChange.bind(this); 
  }
  
  onChange() {
    if(this.state.value === this.state.numSlides - 1) {
      this.setState({ value: 0 });
    } else {
      this.setState((prevState) =>({ 
          value: prevState.value + 1
      }))
    }

  }
  componentDidMount() {
    // setInterval(this.onChange, this.state.timeOut)
  }
  
  render() {
    return (
    <div>
      <Carousel
        value={this.state.value}
        slides={[
          (<LandingSection />),
          (
            <CarouselPage
              headline={"New Releases"}
              backgroundImage={newProductsBackground} 
              splitScreen={true}
              // content on side, assumes the image will be on the right side.
              contentLeftSide={true}
              contentRightSide={false}
              subheading={"Coming Soon"}
              videoButton={false}
              videoLink={""}
              videoText={""}
              downloadButton={false}
              downloadText={""}
              downloadLink={""}
              storeButton={false}
              storeLink={""}
              storeText={""}
              opacityColor={"beige"}
              imageOpacityColor={"white"}
            />
          ),
        ]}
        plugins={[
          'infinite',
          {
            resolve: autoplayPlugin,
            options: {
            interval: 2000,
            }
          },
        ]}
        draggable={false}   
        animationSpeed={1000}
      />
    </div>
    );
  }
}

export default CarouselLandingSection;