import React from 'react';

export const MailChimpForm = () => {
    return (
      <div className="mail-chimp-container">
        <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7_dtp.css" rel="stylesheet" type="text/css" />
        <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\t#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }\n\t/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.\n\t   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */\n" }} />
        <div id="mc_embed_signup">
          <form action="https://materni.us20.list-manage.com/subscribe/post?u=f3e1204963a6fbe19403d4327&id=2da8146c12" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
            <div id="mc_embed_signup_scroll">
              <div className="mail-chimp-container-header">
                <h2>Subscribe Today</h2>
                <p className="body-text">Catch up on what's new at Materni, including product news, parenting tips, promos + more.</p>
              </div>
              {/* <div className="indicates-required"><span className="asterisk">*</span> indicates required</div> */}
              <div className="mc-field-group">
                <label htmlFor="mce-EMAIL">Email Address  <span className="asterisk">*</span>
                </label>
                <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" />
              </div>
              {/* <div className="mc-field-group">
                <label htmlFor="mce-FNAME">First Name </label>
                <input type="text" name="FNAME" className id="mce-FNAME" />
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-LNAME">Last Name </label>
                <input type="text" name="LNAME" className id="mce-LNAME" />
              </div> */}
							<div id="mce-responses" className="clear foot">
                <div className="response" id="mce-error-response" style={{display: 'none'}} />
                <div className="response" id="mce-success-response" style={{display: 'none'}} />
              </div>    {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
              <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_f3e1204963a6fbe19403d4327_2da8146c12" tabIndex={-1} defaultValue /></div>
              <div className="mail-chimp-container-submit">
                <div className="clear foot">
                  <input type="submit" defaultValue="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button mail-chimp-container-submit-btn" />
                </div>
              </div>
            </div>
          </form>
        </div>
        {/*End mc_embed_signup*/}
      </div>
    );
  }