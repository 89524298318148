import React from "react";
import CONFIG from "../config/appConfig";
import ProductTile from "./productTile";
import SplitSection from "./splitSection";
import bottleMeasurementSide from "../assets/img/product/materni-bottle-back.jpg";
import retractableNozzle from "../assets/img/product/materni-bottle-retracted.jpg";
import retractableBottle from "../assets/img/product/materni-retractable-nozzle.jpg";
import nozzle from "../assets/img/product/materni-nozzle.jpg";
import nozzleExploded from "../assets/img/product/materni-nozzle-exploded-view.jpg";
import explodedView from "../assets/img/product/materni-exploded-view.jpg";
import measurementMarkings from "../assets/img/product/materni-measurement-markings.jpg";

const FeatureSection = () => {
  return (
    <div id="features">
      <div class="feature-section" style={{backgroundColor: `${CONFIG.THEME.TERTIARY}`}}>
        <div class="container">
			<SplitSection 
				contentOnRight={false}
				imageFullCover={false}
				imageClass={"section-image-round"}
				roundedImage={true}
				image={measurementMarkings}
				content={
					{
						headline: "Translucent with Measurements",
						// subheading: "We Drive For Better Products",
						description: "Quickly know how much water is in the bottle at a glance, to avoid multiple refills during your cleanse, or to accurately mix solutions and other healing ingredients during your recovery.",
						sectionColor: CONFIG.THEME.TERTIARY,
						fontColor: CONFIG.THEME.FONT_PRIMARY,
					}
				}
			/>
			<SplitSection 
				contentOnRight={true}
				imageFullCover={false}
				imageClass={"feature-section-square-image"}
				roundedImage={false}
				image={nozzle}
				content={
						{
							headline: "Control and Precision",
							description: "With an angled nozzle for precise control and 10 holes for outflow, enjoy a continuous soothing cleanse by directing comforting relief to the right areas.",
							sectionColor: CONFIG.THEME.TERTIARY,
							fontColor: CONFIG.THEME.FONT_PRIMARY,
						}
					}
			/>
			<SplitSection 
				contentOnRight={false}
				imageFullCover={false}
				imageClass={"feature-section-square-image"}
				roundedImage={false}
				image={retractableBottle}
				content={
					{
						headline: "Retractable Nozzle",
						description: "With a 17 oz / 500ml capacity, the nozzle is easily retractable allowing for great portability without compromising its large storage convenience.",
						sectionColor: CONFIG.THEME.TERTIARY,
						fontColor: CONFIG.THEME.FONT_PRIMARY,
					}
				}
			/>
			<SplitSection 
				contentOnRight={true}
				imageFullCover={false}
				imageClass={"feature-section-square-image"}
				roundedImage={false}
				image={nozzleExploded}
				content={
						{
							headline: "Versatile and Travel Friendly",
							description: "With its nozzle cap and leakage-free rubber sealant, it is easily portable to acommpany you during the postpartum process or even on the go after post-recovery, the Materni bottle is here for you and a must for any hospital bag.",
							sectionColor: CONFIG.THEME.TERTIARY,
							fontColor: CONFIG.THEME.FONT_PRIMARY,
						}
					}
			/>

					{/* <img className="feature-section-image" src={measurementMarkings} /> */}
        </div>
      </div>
    </div>
  )
}

export default FeatureSection;