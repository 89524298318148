import React from "react";
import CONFIG from "../config/appConfig"

const EmailSection = (props) => {
  return (
    <div class="section text-center" style={{backgroundColor: `${CONFIG.THEME.BEIGE}`}}>
      <div class="section-content">
        <h3 style={{color: `${CONFIG.THEME.FONT_PRIMARY}`}}>{props.heading}</h3>
        <p class="paragraph-remove-padding body-text contact-subheading">{props.subheading}</p>
          <p class="body-text contact-email">
            <a href={`mailto:${props.email}`} target="__blank">{props.email}</a>
          </p>
        <br />
        {props.sectionBtn ? (
        <a href="#" class="btn btn-danger btn-fill">{CONFIG.VISION_SECTION.BTN_TEXT}</a>
        ) : null}
      </div>    
    </div> 
  )
}

const ContactSection = () => {
  return (
    <div id="contact">
			<EmailSection 
				// description= {CONFIG.CONTACT_SECTION.DESC}
				description= {"business@materni.ca"}
				email={CONFIG.CONTACT_SECTION.EMAIL}
				sectionBtn = {CONFIG.CONTACT_SECTION.BTN}
				sectionBtnTxt={CONFIG.CONTACT_SECTION.BTN_TEXT}
				heading={CONFIG.CONTACT_SECTION.HEADING}
				subheading={CONFIG.CONTACT_SECTION.SUBHEADING}
			/>
    </div>
  )
}

export default ContactSection;