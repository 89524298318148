/**
 * The title still needs to be changed in index.html
 */ 

 //Update following assets for website
import COMPANY_LOGO from "../assets/img/materni-logo.png";
import LANDING_SECTION_BACKGROUND_IMG2 from "../assets/img/mother-baby.jpeg";
import PRODUCT_ONE from "../assets/img/coconut-oil.jpg";
import PRODUCT_TWO from "../assets/img/soaps-and-oils.jpeg";
import PRODUCT_THREE from "../assets/img/epsum-salt-baths.jpg";
import PRODUCT_FOUR from "../assets/img/colorful-soaps.jpeg";
import PRODUCT_CATALOG from "../assets/doc/product-catalog.pdf";
import INSTRUCTIONS from "../assets/doc/instructions.pdf";

const eggshell = "#f0ead6";
const cosmicLatte = "#fff8e7";

const LIGHT_THEME = {
  PRIMARY: "#FFFCF5",
  SECONDARY: "#F4F3EF",
  TERTIARY: "	#FFFFFF",
  FONT_PRIMARY: "#000000",
  WHITE: "#00000",
  RED: "#e05555",
  GREY: "828282",
  BEIGE: "e8cda3",
}

const CONFIG = {
  COMPANY_NAME: "materni",
  COMPANY_URL: "/",
  COMPANY_LOGO: COMPANY_LOGO,
  SITE_SECTIONS: {
    LANDING_SECTION: true,
    PRODUCT_SECTION: true,
    VISION_SECTION: true,
    CONTACT_SECTION: true,
    COMPANY_PROFILE_SECTION: false,
    ABOUT_SECTION: true,
  },
  THEME: LIGHT_THEME,
  NAVIGATION: [
    { NAME: "Peri Bottle", LINK: "#product", },
		{ NAME: "Features", LINK: "#features" },
		{ NAME: "How It Works", LINK: "#instructions", },
    { NAME: "Contact", LINK: "#contact", }
  ],
  TWITTER_URL: "",
  FACEBOOK_URL: "",
  DISCOUNT_SETTINGS: {
    IS_DISCOUNT: false,
    DISCOUNT: 10,
    DISCOUNT_EXP_DATE: "5th of November",
    DISCOUNT_TEXT: "Free Shipping for Orders over $75",
    DISCOUNT_FONT_COLOR: "white",
    DISCOUNT_BACKGROUND_COLOR: "#efdbb2"
  },
  LANDING_SECTION: {
    IMG: LANDING_SECTION_BACKGROUND_IMG2,
    IMG_FULL_WIDTH: false,
    SPLIT_SCREEN: false,
    CONTENT_RS: true,
    CONTENT_LS: false,
    HEADLINE: "Be Prepared",
    SUB_HEAD: "Newly Designed Postpartum Bottle",
    VIDEO_BTN: false,
    VIDEO_LINK: "https://www.youtube.com/watch?v=-C-2AqRD8io",
    VIDEO_TEXT: "Watch Video",
    DOWNLOAD_BTN: false,
    DOWNLOAD_LINK: PRODUCT_CATALOG,
    DOWNLOAD_TEXT: "Product Catalog",
    STORE_BTN: true,
    STORE_LINK: "https://www.amazon.ca/Semi-Transparent-Materni-Postpartum-Essentials-Hemorrhoids/dp/B08P6JLKN7/ref=sr_1_54?dchild=1&keywords=peri+bottle&qid=1617993353&sr=8-54",
    STORE_TEXT: "Buy Now on Amazon"
  },
	HOW_IT_WORKS_SECTION: {
		DOWNLOAD_LINK: INSTRUCTIONS,
	},
  VISION_SECTION : {
    HEADING: "Our Ethos",
    SUBHEADING: "Why We Do What We Do",
    DESC: "",
    BTN: false,
    BTN_TEXT: "See Details"
  },
  PRODUCT_SECTION: {
    PRODUCTS: [
      {
        NAME: "Organic Coconut Oil",
        QTY: "15oz",
        IMAGE: PRODUCT_ONE,
        DESC: "",
        PRICE: 10
      },
      {
        NAME: "Soaps and Oil Kit",
        QTY: "1 Kit",
        IMAGE: PRODUCT_TWO,
        DESC: "",
        PRICE: 35
      },
      {
        NAME: "Bath Salts & Soaks",
        QTY: "18oz",
        IMAGE: PRODUCT_THREE,
        DESC: "",
        PRICE: 25
      },
      {
        NAME: "Maternity Pads",
        QTY: "28 pcs",
        IMAGE: PRODUCT_FOUR,
        DESC: "",
        PRICE: 10
      }
    ],
  },
  ABOUT_SECTION: {
    HEADING: "About Materni",
    DESC: "The basis for the concept of sustainability in the world of consumerism is buying items produced efficiently, using minimal amounts of raw materials, water and energy. We here at Materni follow this philosophy. The selection of recycled and ethically sourced products available at our Ecom Sustainable Hygiene Products Store is extremely varied and of the highest quality."
  },
  CONTACT_SECTION: {
    HEADING: "Contact Us",
    SUBHEADING: "We want to hear from you",
    EMAIL: "business@materni.ca",
    BTN: false,
    BTN_TEXT: "See Details"
  }
};

export default CONFIG;
