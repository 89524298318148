import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from "history";
import PageContainer from "./containers/pageContainer";

const history = createBrowserHistory();

class Routes extends Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={PageContainer} /> 
        </Switch>
      </Router>
    )
  }
}

export default Routes