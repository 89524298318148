import React from "react";
import CONFIG from "../config/appConfig";

const ContentSection = (props) => {
  return(
    <div class="section-content" style={{color: `${props.content.fontColor}`, backgroundColor: `${props.content.divcolor}`}}>
      <h3>
      {props.content.headline}
      </h3>
      <h6>
      {props.content.subheading}
      </h6>
      <p class="body-text">
        {props.content.description}
      </p>
    </div>
  )
}

const SplitSection = (props) => {
	let sectionImageCoverClass = props.roundedImage ? "section-image-cover section-image-cover-border" : "section-image-cover";
  return(
    <div class="landing-header">
      <div class="container-split">
        <div class="landing-header--split-screen-container">
           {props.contentOnRight ? (
              props.imageFullCover ? 
                (
                <div class="landing-header-pane section-pane image-section-pane">
                  <div class="section-image" style={{backgroundImage: `url(${props.image})`, backgroundSize: `cover`}}/>
                </div>
                ) 
                : (
                <div class="landing-header-pane section-pane image-section-pane">
                  <div className={sectionImageCoverClass}><img class={props.imageClass} src={props.image} /></div>
                </div>
                )
            ) : 
           (
             <div class="landing-header-pane section-pane content-section-pane">
              <ContentSection
                content={props.content}
                color={props.sectionColor}
                fontColor={props.fontColor}
              />
             </div>
            )} 
            {props.contentOnRight ? (
              <div class="landing-header-pane section-pane content-section-pane">
                <ContentSection
                  content={props.content}
                  color={props.sectionColor}
                  fontColor={props.fontColor}
                />
              </div>
              ) : (
                props.imageFullCover ? 
                (
                <div class="landing-header-pane section-pane image-section-pane">
                    <div class="section-image" style={{backgroundImage: `url(${props.image})`, backgroundSize: `cover`}}/>
                </div>
                ) 
                : (
                  <div class="landing-header-pane section-pane image-section-pane">
                    <div className={sectionImageCoverClass}><img class={props.imageClass} src={props.image} /></div>
                  </div>
                )
            )
            }
        </div>
      </div>    
    </div>
  )
}

export default SplitSection;